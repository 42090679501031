<template>
  <div id="app">
    <div class="page-wrap" id="page-wrap">

      <over-layer></over-layer>
      <router-view/>

    </div>
  </div>
</template>
<script>
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import overLayer from '@/views/elements/OverLayer.vue'

export default {
  name: 'app',
  components: {
    overLayer
  },
  data: function(){
    return {
    }
  },
  created: function(){
  }
}
</script>
<style lang="scss">
@import './assets/css/animate.css';
@import './assets/scss/theme.scss';
</style>
