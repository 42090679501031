import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vueHeadful from 'vue-headful'
import Vue2TouchEvents from 'vue2-touch-events'

import VueFilterDateFormat from 'vue-filter-date-format';
var VueScrollTo = require('vue-scrollto');

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-L58JXDKW9N" }
}, router);


Vue.config.productionTip = false
Vue.use(VueFilterDateFormat, {
  monthNames: [ 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
  monthNamesShort: [ 'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec' ]
});
/*GOOGLE MAPS
Vue.use(VueGoogleMaps, {
  load: { key: '#', libraries: 'places,drawing,visualization', v: '3.36' },
})*/
Vue.use(VueScrollTo)
Vue.use(Vue2TouchEvents)

Vue.component('vue-headful', vueHeadful);

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
