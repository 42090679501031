import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "pages" */ './views/pages/Home.vue')
    },
    {
      path: '/qr1',
      name: 'qr1',
      component: () => import(/* webpackChunkName: "pages" */ './views/pages/Qr1.vue')
    },
    { path: '/qr2', redirect: { name: 'home' }},
    {
      path: '/qr3',
      name: 'qr3',
      component: () => import(/* webpackChunkName: "pages" */ './views/pages/Qr3.vue')
    },
    {
      path: '/tunnel-des-nations',
      name: 'tunnel',
      component: () => import(/* webpackChunkName: "pages" */ './views/pages/Tunnel.vue')
    },
    {
      path: '/tunnel-des-nations/panorama-nations-ferney',
      name: 'panorama-nations-ferney',
      component: () => import(/* webpackChunkName: "slides" */ './views/pages/slides/PanoramaNationsFerney.vue')
    },
    {
      path: '/tunnel-des-nations/tranchee-couverte',
      name: 'tranchee-ouverte',
      component: () => import(/* webpackChunkName: "slides" */ './views/pages/slides/TrancheeCouverte.vue')
    },
    {
      path: '/pont-grand-saconnex',
      name: 'pont-grand-saconnex',
      component: () => import(/* webpackChunkName: "pages" */ './views/pages/PontGrandSaconnex.vue')
    },
    {
      path: '/pont-grand-saconnex/story',
      name: 'story',
      component: () => import(/* webpackChunkName: "slides" */ './views/pages/slides/Story.vue')
    },
    {
      path: '/pont-grand-saconnex/video',
      name: 'video',
      component: () => import(/* webpackChunkName: "slides" */ './views/pages/slides/Video.vue')
    },
    {
      path: '/pont-grand-saconnex/phasage-du-pont',
      name: 'story',
      component: () => import(/* webpackChunkName: "slides" */ './views/pages/slides/PhasageDuPont.vue')
    },
    {
      path: '/observatoire',
      name: 'observatoire',
      component: () => import(/* webpackChunkName: "pages" */ './views/pages/Observatoire.vue')
    },
    {
      path: '/observatoire/amenagement-du-siecle',
      name: 'amenagementDuSiecle',
      component: () => import(/* webpackChunkName: "slides" */ './views/pages/slides/AmenagementDuSiecle.vue')
    },
    {
      path: '/observatoire/gestion-des-terres',
      name: 'gestion-des-terres',
      component: () => import(/* webpackChunkName: "slides" */ './views/pages/slides/GestionDesTerres.vue')
    },
    {
      path: '/observatoire/defrichements-et-plantations',
      name: 'defrichements-et-plantations',
      component: () => import(/* webpackChunkName: "slides" */ './views/pages/slides/DefrichementsPlantations.vue')
    },
    {
      path: '/observatoire/amenagements-paysagers',
      name: 'amenagements-paysagers',
      component: () => import(/* webpackChunkName: "slides" */ './views/pages/slides/AmenagementsPaysagers.vue')
    },
    {
      path: '/bois-brule',
      name: 'bois-brule',
      component: () => import(/* webpackChunkName: "pages" */ './views/pages/BoisBrule.vue')
    },
    {
      path: '/bois-brule/pont-du-bois-brule',
      name: 'pont-du-bois-brule',
      component: () => import(/* webpackChunkName: "slides" */ './views/pages/slides/PontDuBoisBrule.vue')
    },
    {
      path: '/pont-colovrex',
      name: 'pont-colovrex',
      component: () => import(/* webpackChunkName: "pages" */ './views/pages/Colovrex.vue')
    },
    {
      path: '/pont-colovrex/pont-de-colovrex',
      name: 'pont-de-colovrex',
      component: () => import(/* webpackChunkName: "slides" */ './views/pages/slides/PontDeColovrex.vue')
    },
    {
      path: '/pont-colovrex/demolition',
      name: 'pont-de-colovrex-demolition',
      component: () => import(/* webpackChunkName: "slides" */ './views/pages/slides/PontDeColovrexDemolition.vue')
    },
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})
