<template lang="html">

  <section class="section section--over-layer animated" :class="show? 'fadeIn': 'fadeOut'">

    <div class="splash">
      <img class="splash__img" src="@/assets/img/slpash--intro.jpg" alt="">
    </div>
    <div class="splash splash--shader"></div>

    <div v-if="showLogo" class="logo--swiss">
      <img src="@/assets/img/JAG_logo-25.svg" alt="">
    </div>

    <div ref="logoJag" v-if="showLogo" class="logo--jag animated">
      <img src="@/assets/img/JAG_logo-24.svg" alt="">
    </div>

    <div ref="textJag" v-if="showText" class="welcome-text animated">
      <div class="">
        <h4>Bienvenue au JAGPOINT de l’OFROU</h4>
        <p>
          Ce point d’observation interactif vous renseigne sur la nouvelle jonction du Grand-Saconnex. Au cœur de cet important chantier, l’axe entre le tunnel des Nations et le tunnel de Ferney permettra, à terme, de fluidifier le trafic et de soulager le réseau secondaire.
          L’OFROU souhaite ici vous apporter une compréhension aussi bien technique qu’historique, géologique ou environnementale du développement des infrastructures et de la mobilité  prévues dans ce projet.
        </p>
      </div>
    </div>

    <a v-if="showBack" href="#" class="back" @click.prevent="postIntro()">
      <i class="material-icons">clear</i>
    </a>

  </section>

</template>

<script>
import VueScreenSize from 'vue-screen-size'

export default
{
  data()
  {
    return {
      show: false,
      showLogo: false,
      showText: false,
      showBack: false,
      interval: null,
      introDurration: 3000,
      welcomeDurration: 10000
    }
  },
  mounted()
  {
    this.$root.$on('over-layer--intro', this.intro)
    this.$root.$on('over-layer--intro-and-welcome', this.introAndWelcome)
  },
  mixins: [VueScreenSize.VueScreenSizeMixin],
  methods:
  {
    introAndWelcome()
    {
      this.show = this.showLogo = true
      this.interval = setInterval(this.postIntroAndWelcome, this.introDurration)
    },
    postIntroAndWelcome()
    {
      clearInterval(this.interval)
      this.showText = true
      this.showBack = true

      var comp = this
      setTimeout(function(){
        comp.$refs.logoJag.classList.add('fadeOut')
        comp.$refs.textJag.classList.add('fadeIn')
        comp.interval = setInterval(comp.postIntro, comp.welcomeDurration)
      }, 0)
    },
    intro()
    {
      if(this.interval !== null) clearInterval(this.interval)

      this.show = this.showLogo = true
      this.showText = this.showWarning =false
      this.interval = setInterval(this.postIntro, this.introDurration)
    },
    postIntro()
    {
      if(this.interval !== null) clearInterval(this.interval)
      this.show = false
    }
  }
}
</script>
